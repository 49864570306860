import { useState, useEffect } from "react";

import PopoverIcon from "../Popover/PopoverIcon";

import { FiExternalLink } from "react-icons/fi";
import { GrCaretNext, GrCaretPrevious } from "react-icons/gr";

import previewicon from "../../assets/images/icons/previewIcon.png";
import closePreviewIcon from "../../assets/images/icons/closePreviewIcon.png";

import styles from "./Projects.module.css";

import projectData from "../../assets/projectData/projectData";

const Projects = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [showPreview, setShowPreview] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [imgIndex, setImgIndex] = useState(0);

  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateScreenWidth);
    return () => {
      window.removeEventListener("resize", updateScreenWidth);
    };
  }, []);

  const projectPreviewClickHandler = (project) => {
    document.body.classList.add(styles["modal-open"]);
    setShowPreview(true);
    setSelectedProject(project);
  };

  const closeProjectPreviewHandler = () => {
    setShowPreview(false);
    setImgIndex(0);
    document.body.classList.remove(styles["modal-open"]);
  };

  const nextImgClickHandler = (e) => {
    if (e.target.value === "Next") {
      console.log("Next btn hit");
      setImgIndex((preVal) => (preVal += 1));
    } else {
      setImgIndex((preVal) => (preVal -= 1));
    }
  };
  
  const projectOverlay = selectedProject && (
    <div className={styles["overlay-backdrop"]}>
      <div className={styles["overlay-content"]}>
        <img 
          src={closePreviewIcon}
          alt="Exit Preview"
          onClick={closeProjectPreviewHandler}
          className={styles["close-preview-icon"]}
        />
        <img
          src={selectedProject.images[imgIndex]}
          alt={selectedProject.title}
          className={styles[selectedProject.title.replace(/\s+/g , "").toLowerCase()]}
        />
        <div>
          {imgIndex !== 0 && (
            <button onClick={nextImgClickHandler} value="back">
              <GrCaretPrevious className={styles.imgBtn} />
            </button>
          )}

          {selectedProject.images.length - imgIndex !== 1 && (
            <button onClick={nextImgClickHandler} value="Next">
              <GrCaretNext className={styles.imgBtn} />
            </button>
          )}

        </div>
      </div>
    </div>
  );

  return (
    <section className={styles["projects-container"]} id="projects">
      <div className={styles["projects-intro"]}>
        <h2>My Recent Work</h2>
        <p>
          Here are a past few projects I've worked on. Want to know more?{" "}
          <a href="#contact">Hit me below</a>.
        </p>
      </div>
      {showPreview && projectOverlay}
      <div className={styles["projects-div"]}>
        {projectData.map((project, idx) => {
          return (
            <div className={styles["one-project"]} key={idx}>
              <div>
                <h2>{project.title}</h2>
                {project.trophy && (
                  <PopoverIcon project={project} className={styles["trophy"]} />
                )}
              </div>
              <p className={styles.languages}>
                <span>Languages Used:</span> {project.languagesUsed}
              </p>
              <p className={styles.caption}>{project.caption}</p>
              <div>
                <a href={project.link} target="_blank" rel="noreferrer">
                  <FiExternalLink className={styles.icon} />
                  {screenWidth > 430 && project.linkText}
                </a>
                {project.preview && (
                  <img
                    src={previewicon}
                    alt="Preview Project"
                    className={styles["preview-icon"]}
                    onClick={() => projectPreviewClickHandler(project)}
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Projects;
