import Skills from "../Skills/Skills";

import linkedInIcon from "../../assets/images/icons/linkedInIcon.png";
import githubIcon from "../../assets/images/icons/githubIcon.png";
import instagramIcon from "../../assets/images/icons/instagramIcon.png";
import contactIcon from "../../assets/images/icons/contactIcon.png";

import styles from "./About.module.css";

const About = () => {
  return (
    <section className={styles["about-section"]} id="about">
      <div>
        <h1>
          Hi, I'm Max.
          <br />
          Pleased to meet you!
        </h1>
        <h2>
          Since beginning my journey into development, I've
          worked as a Front-End Developer and as an Instructor with General
          Assembly. I'm quietly confident, naturally curious, and am constantly
          aiming to hone my skills one design challenge at a time.
        </h2>

        <div>
            <a href="https://www.linkedin.com/in/walker-max/" target="_blank" rel="noreferrer"><img src={linkedInIcon} alt="LinkedIn Icon"/></a>
            <a href="https://github.com/Max-V-Walker/" target="_blank" rel="noreferrer"><img src={githubIcon} alt="GitHub Icon"/></a>
            <a href="https://www.instagram.com/kingbetes/" target="_blank" rel="noreferrer"><img src={instagramIcon} alt="Instagram Icon"/></a>
            <a href="#contact"><img src={contactIcon} alt="Contact Icon"/></a>
        </div>
      </div>

      <Skills />
    </section>
  );
};

export default About;
