import NavBar from "../NavBar/NavBar";
import { useTypewriter, Cursor } from "react-simple-typewriter";

import HeroImg from "../../assets/images/hero-img.jpg";

import styles from "./Hero.module.css";

const Hero = () => {
  const [text] = useTypewriter({
    words: [
      "Front-End Dev",
      "Automation Dev",
      "Problem Solver",
      "Point Guard",
      "Gamer",
    ],
    loop: {},
  });

  return (
    <>
      <NavBar />
      <section className={styles["hero-section"]} id="hero">
        <div className={styles["hero-container"]}>
          <div>
            <div>
              <p>Hi,</p>
              <p>I'm Max.</p>
            </div>
            <div>
              <p>
                I'm a <br />
                <span>
                  &lt;<p>{text}</p> <Cursor cursorStyle="/>" />
                </span>
              </p>
            </div>
          </div>

          <img
            src={HeroImg}
            alt="Max Walker"
            className={styles["hero-image"]}
          />
        </div>
      </section>
    </>
  );
};

export default Hero;
